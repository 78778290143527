import * as React from 'react'

import { cn } from '#app/utils/misc.tsx'

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> { }

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, ...props }, ref) => {
		return (
			<input
				type={type}
				className={cn(
					'flex h-10 w-full px-3 py-2 rounded-md',
					'border border-black/15 bg-white/70 file:border-0 file:bg-transparent',
					'text-base font-medium placeholder:text-foreground-placeholder',
					'ring-offset-background focus-visible:ring-2 focus-visible:ring-green-700 focus-visible:outline-none',
					'disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid]:border-input-invalid',
					className,
				)}
				ref={ref}
				{...props}
			/>
		)
	},
)
Input.displayName = 'Input'

export { Input }
